import { cn } from "./anim/cn";

import {Tilt} from "react-tilt";
import { styles } from "../styles";
import { fadeIn } from "../utils/motion";
import { motion } from "framer-motion";


import React from "react";
import { BentoGrid, BentoGridItem } from "./ui/bento-grid";
import {
    IconArrowWaveRightUp,
    IconBoxAlignRightFilled,
    IconBoxAlignTopLeft,
    IconClipboardCopy,
    IconFileBroken,
    IconSignature,
    IconTableColumn,
  } from "@tabler/icons-react";
   
  export function Leetcode() {
    return (
      <>
   <div className='mb-12 '  id='opensource'><h1><br></br></h1></div>

<div className=" mt-10">
        <div className="">
        <h2 className={`${styles.sectionHeadText}`}>Problem Solving.</h2>

<div className='w-full flex'>
  <motion.p
    variants={fadeIn("", "", 0.1, 1)}
    className='mt-3 mx-0 sm:mx-10 text-secondary mb-10 text-[17px] max-w-3xl leading-[30px]'
  >
    I love to solve Problem and i waste time solving problems on Leetcode.
  </motion.p>
</div>
        </div>

</div>
<BentoGrid className=" max-w-4xl mb-10 ">
        {items.map((item, i) => (
          <div className= "flex center  my-10 sm:h-[500px] sm:w-[800px]">
            <a href={item.link}> 
          <BentoGridItem
            key={i}
            title={item.title}
            description={item.description}
            header={item.header}
            className={i === 3 || i === 6 ? "md:col-span-2" : ""}
          /></a>
            </div>
        ))}
      </BentoGrid>

      </>
    );
  }
  const Skeleton = (props) => (
    <div className="flex flex-1 w-full h-full min-h-[6rem] rounded-xl bg-gradient-to-br  from-neutral-900 to-neutral-800 ">
    <img src={props.imgage}  style={{ width: '100%', height: '100%', objectFit: 'cover' }}></img>
    </div>
  );
  const items = [
    {
      title: "Leetcode",
      description: "Check Me out On Leetcode.",
      header: <Skeleton imgage={"./images/leetcode.png"}/>,
      link:"https://leetcode.com/aniketking"
    },  
  ];