import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import React from 'react'
import {ReactComponent as ReactLogo} from './aniket.svg';

const navigation = [
//   { name: 'home', href: '/', current: true },
  { name: 'About', href: '#about', current: false },
  { name: 'Projects', href: '#project', current: false },
  { name: 'OpenSource', href: '#opensource', current: false },

  { name: 'Resume', href: '#downloadresume', current: false },
  { name: 'Contact', href: '#contact', current: false },
//   { name: 'Calendar', href: '#', current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Navbar(props) {
   
  return (
    <Disclosure as="nav" className="fixed top-0 w-full z-10 bg-white  bg-opacity-5  backdrop-blur-xl ">
      {({ open }) => (
        <>
          <div className=" mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                 
                </div>
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                     
            {/* <a href="#land" className=" text-white hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium" aria-current="page">Home</a> */}
            {<a href="#about" className="text-gray-300 hover:bg-gray-900 hover:text-white rounded-md px-3 py-2 text-sm font-medium">About</a>}
            {<a href="#project" className="text-gray-300 yo:bg-gray-700 hover:bg-gray-900 hover:text-white rounded-md px-3 py-2 text-sm font-medium">Projects</a>}
            {<a href="#opensource" className="text-gray-300 yo:bg-gray-700 hover:bg-gray-900 hover:text-white rounded-md px-3 py-2 text-sm font-medium">OpenSource</a>}

            {<a href="#downloadresume" className="text-gray-300 yo:bg-gray-700 hover:bg-gray-900 hover:text-white rounded-md px-3 py-2 text-sm font-medium">Resume</a>}

            {<a href="#contact" className="text-gray-300 hover:bg-gray-900 yo:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium">Contact</a>}

                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
              <ReactLogo height={"200px"} width={"80px"}/> 

                {/* Profile dropdown */}
               {props.authstate &&  <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                      <span className="sr-only">Open user menu</span>
                      <img width="44" height="44" src="https://img.icons8.com/nolan/64/user-default.png" alt="user-default"/>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                   
                  </Transition>
                </Menu>}
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            
            <div className="space-y-1 px-2 pb-3 pt-2">
            {/* <a href="#land" className=" text-white hover:bg-gray-700 text-gray-300 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium" aria-current="page">Home</a> */}
              {!props.authstate && navigation.map((item) => (
                <Disclosure.Button
                key={item.name}
                as="a"
                href={item.href}
                className={classNames(
                  item.current ? 'bg-gray-900 text-white' : 'text-gray-300',
                  'block rounded-md px-3 py-2 text-base font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                  >
                  {item.name}
                </Disclosure.Button>
                  
              ))}
                
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}