import { cn } from "./anim/cn";

import {Tilt} from "react-tilt";
import { styles } from "../styles";
import { fadeIn } from "../utils/motion";
import { motion } from "framer-motion";


import React from "react";
import { BentoGrid, BentoGridItem } from "./ui/bento-grid";
import {
    IconArrowWaveRightUp,
    IconBoxAlignRightFilled,
    IconBoxAlignTopLeft,
    IconClipboardCopy,
    IconFileBroken,
    IconSignature,
    IconTableColumn,
  } from "@tabler/icons-react";
   
  export function ColumnGrid() {
    return (
      <>
   <div className='mb-10'  id='opensource'><h1><br></br></h1></div>

<div className=" mt-10">
        <div className="">
        <h2 className={`${styles.sectionHeadText}`}>OpenSource.</h2>

<div className='w-full flex'>
  <motion.p
    variants={fadeIn("", "", 0.1, 1)}
    className='mt-3 mx-0 sm:mx-10 text-secondary mb-10 text-[17px] max-w-3xl leading-[30px]'
  >
    Here are some of the open source contribution which i can publicly disclose.
  </motion.p>
</div>
        </div>

</div>
<BentoGrid className="max-w-4xl ">
        {items.map((item, i) => (
          <BentoGridItem
            key={i}
            title={item.title}
            description={item.description}
            header={item.header}
            className={i === 3 || i === 6 ? "md:col-span-2" : ""}
          />
        ))}
      </BentoGrid>

      </>
    );
  }
  const Skeleton = (props) => (
    <div className="flex flex-1 w-full h-full min-h-[6rem] rounded-xl bg-gradient-to-br  from-neutral-900 to-neutral-800">
    <img src={props.imgage}  style={{ width: '100%', height: '100%', objectFit: 'cover' }}></img>
    </div>
  );
  const items = [
    {
      title: "Lambdatest",
      description: "Fixed the Capabilities of Selenium 4.+ for Python Automation Template.",
      header: <Skeleton imgage={"./images/lambdatest.png"}/>,
    },
    {
      title: "XProfile Card Open Source Project",
      description:
        "An openSource Project Builtup on Next js with the latest Features and With Typescript Safety.",
      header: <Skeleton imgage={"./images/xprofile.png"} />,
    },
    {
      title: "Github CheatSheet",
      description: "Updated the latest info on the cheatSheet of Github.",
      header: <Skeleton  imgage={"./images/cheatsheat.png"}/>,
      
    },
    {
      title: "AI Tools Public Open Source Docs",
      description: "Updated to the latest docs with newest LLM'S and More Ai models and tools.",
      header: <Skeleton imgage={"./images/aitool.png"}/>,
    },
    {
      title: "More At Github ..",
      description: "",
      header: <Skeleton imgage={"./images/more.png"}/>,
    },
    
    
  ];