import About from './components/About'
import Project from "./components/Project";
import Navbar from "./components/Navbar"
import Landing from './components/Landing';
import Solar from './components/enc.webm'
import Contact from './components/Contact';
import Typewriter from './components/Typewriter';
import TestWriter from './components/TestWriter';
import ResumeDownload from './components/ResumeDownload';
import Blog from './components/Blog';
import Social from './components/Social';
import { ColumnGrid } from './components/ColumnGrid';
import {Leetcode} from './components/Leetcode';


function App() {

   document.addEventListener("keydown", e => {
      // DISABLE CONTROL AND ALL FUNCTION KEYS
      // if (e.ctrlKey || (e.keyCode>=112 && e.keyCode<=123)) {
      // DISABLE CONTROL AND F12
      if (e.ctrlKey || e.keyCode==123) {
      e.stopPropagation();
      e.preventDefault();
      }
      });

  
  return (
   <>


   
<div className='mb-20'>
<Navbar about={"about"}/>
</div>
<video  autoPlay muted loop id='Solarplanet'>
     <source src={Solar} />
 </video>





 <br></br>
 <br></br>
 
    <div className="m-5 mx-5">
   <TestWriter/>
   <div className='my-10'>

      {/* <Landing/> */}
      {/* <img src='./images/working.png' height="12px" width={"420px"}></img> */}
   <About/>

<div className=''>
<Project/>
</div>
<ColumnGrid/>
<div className=' z-10 mb-0 sm:mb-80'>
<Leetcode/>
</div>
<ResumeDownload/>
<Contact/>
   </div>
</div>
{/* <Social/> */}
<div className="">
  <img src="./images/roundcircle.png"   id="roundcircle"></img>
  </div>





   </>
  );
}

export default App;
