import React from 'react'
import { useState,useEffect } from 'react';
export default function TestWriter({ appendClass,  prefix }) {
    const hats = [
        {
            prep: '',
            suffix: 'नमस्ते'
          },
        {
          prep: 'मैं',
          suffix: 'Aniket Singh'
        },
        {
          prep: 'एक',
          suffix: 'Programmer'
        },
        {
          prep: 'एक',
          suffix: 'Youtuber'
        },
        {
            prep: 'एक',
            suffix: 'Author'
          },
        {
            prep: 'एक',
            suffix: 'Video Editor'
          },
      ];
      
    let className = "flex flex-col p-5 gap-4 mb-20";
    if (appendClass) className += " " + appendClass;
    // Typewriter effect base class
    const typeWriterClass = "font-bold border-b-2 border-b-blue-400 border-r-2 pr-1"
        + "animate-cursor overflow-hidden whitespace-nowrap transition-[width] ease-in-out duration-1000 mr-auto";
    // State of current hat index
    const [currentHat, setCurrentHat] = useState(0);
    // State to toggle word collapse effect
    const [collapseClass, setCollapseClass] = useState(" w-0");
    useEffect(() => {
        setTimeout(() => setCollapseClass(" w-full"), 100);
        const incrementHat = async () => {
            // Set the width to 0 - transition duration is 1000ms
            setCollapseClass(" w-0");
            setTimeout(() => {
                /**
                 * After 1100ms, change the displayed text while the div
                 * is collapsed by incrementing the index
                 */
                setCurrentHat(oldVal => {
                    let hatIndex;
                    if (oldVal >= hats.length - 1) {
                        hatIndex = 0;
                    } else {
                        hatIndex = oldVal + 1;
                    }
                    return hatIndex;
                });
            }, 1100);
            // After 1000ms, set width to 100% - transition duration is 1000ms
            setTimeout(() => {
                setCollapseClass(" w-full");
            }, 1000);
        }
        // Interval timer to change text every 4000ms
        const id = setInterval(incrementHat, 4000);
        // Cleanup interval timer
        return () => clearInterval(id);
    }, []); //  eslint-di
  return (
    <>
     <div className={className}>
            {/* <div className="text-5xl md:text-6xl text-center mx-auto">
              Aniket Singh
            </div> */}
            <div className="flex gap-2 text-3xl md:text-6xl mx-auto">
                <div className="shrink-0 whitespace-nowrap ml-auto">
                    {prefix}
                    {hats[currentHat].prep ? ` ${hats[currentHat].prep} ` : ''}
                </div>
                <div className={`${typeWriterClass}${collapseClass}`}>{hats[currentHat].suffix}</div>
            </div></div>
    </>
  )
}
