import React from 'react'
import { motion } from "framer-motion";
import { fadeIn, textVariant } from "../utils/motion";
import {Tilt} from "react-tilt";
import { styles } from "../styles";


const ProjectCard = ({
    index,
    name,
    description,
    tags,
    image,
    source_code_link,
    url
  }) => {
    return (
     <div className=''>
      <a href={url}>
       <motion.div variants={fadeIn("up", "spring", index * 0.5, 0.75)}>
        <Tilt
          options={{
            max: 45,
            scale: 1,
            speed: 450,
          }}
          className=' bg-gradient-to-r  from-indigo-950 to-black   hover:text-white
         hover:background-animate
         cursor-pointer p-3 rounded-2xl sm:w-[310px] w-full'
        >
          <div className='relative w-full h-[230px]'>
            <img
              src={image}
              alt='project_image'
              className='w-full h-full object-cover rounded-2xl'
            />
  
            <div className='absolute inset-0 flex justify-end m-3 card-img_hover'>
              <div
                onClick={() => window.open(source_code_link, "_blank")}
                className='black-gradient w-10 h-10 rounded-full flex justify-center items-center cursor-pointer'
              >
                <img 
                  src={"https://img.icons8.com/3d-fluency/94/github.png"}
                  alt='source code'
                  className='w-1/2 h-1/2 object-contain'
                />
              </div>
            </div>
          </div>
  
          <div className='mt-5'>
            <h3 className=' font-bold text-[24px]'>{name}</h3>
            <p className='mt-2 text-secondary text-[14px]'>{description}</p>
          </div>
  
          <div className='mt-4 flex flex-wrap gap-2 '>
            {tags.map((tag) => (
              <div className="bg-black rounded-2xl">
                <p
                key={`${name}-${tag.name}`}
                className={`text-[14px]  mx-2 ${tag.color}`}
              >
                #{tag.name}
              </p></div>
            ))}
          </div>
        </Tilt>
      </motion.div></a>
     </div>
    );
  };


export default function Project() {
  
  const projects = [
    {
      name: "Blitz",
      description:
        "A Production level application which i can't explain much in shourt,Click on the project to read the docs.",
        url:"https://blitz-frontend.vercel.app",
      tags: [
        {
          name: "nextjs",
          color: "blue-text-gradient",
        },
        {
          name: "redux",
          color: "green-text-gradient",
        },
        
        {
          name: "nodejs",
          color: "orange-text-gradient",
        },
      ],
      image: "./images/blitz.png",
      source_code_link: "https://blitz-frontend.vercel.app",
    },
    {
      name: "Lab Management",
      description:
        "Another Production Level Application with Redux and 3 Tier Architecture with Roubust and Scalable Tech Stack.Can't Provide the link as of now",
        url:"#",
      tags: [
        {
          name: "nextjs",
          color: "blue-text-gradient",
        },
        {
          name: "redux",
          color: "green-text-gradient",
        },
        
        {
          name: "nodejs",
          color: "orange-text-gradient",
        },
      ],
      image: "./images/labmanagement.png",
      source_code_link: "#",
    },
    {
      name: "Auth Post",
      description:
        "A Post Webapp which is backed by nodejs(express) with JWT auth and realtime data processing",
        url:"https://anicreate-devops.vercel.app",
      tags: [
        {
          name: "react",
          color: "blue-text-gradient",
        },
        {
          name: "mongodb",
          color: "green-text-gradient",
        },
        
        {
          name: "nodejs",
          color: "orange-text-gradient",
        },
      ],
      image: "./images/devops.jpg",
      source_code_link: "https://anicreate-devops.vercel.app",
    },
    {
      name: "Search Engine ",
      description:
        "A search engine with suggestions,auto-correct using azure api's.Text,video,image search also works.",
        url:"https://websearch-lyart.vercel.app/",
      tags: [
        {
          name: "react",
          color: "orange-text-gradient",
        },
       
        {
          name: "nodejs",
          color: "pink-text-gradient",
        },
        {
          name: "Azure",
          color: "blue-text-gradient",
        },
        
       
      ],
      image: "./images/searchengine.png",
      source_code_link: "https://github.com/DUMBANIKET",
    },
    {
      name: "JWT Auth Service (API)",
      description:
        "An authentication service using nodejs with jwt and email verification.",
        url:"https://websearch-lyart.vercel.app/",
      tags: [
        {
          name: "express",
          color: "orange-text-gradient",
        },
        {
          name: "jwt",
          color: "blue-text-gradient",
        },
       
        {
          name: "nodejs",
          color: "pink-text-gradient",
        },
        
        
       
      ],
      image: "./images/jwtauth.png",
      source_code_link: "https://github.com/TRUTHANIKET/jwt_auth",
    },
    {
      name: "Car3D",
      description:
        "A 3D car showcase car application which works by using images from all angles to give a 3D look.",
        url:"",
      tags: [
        {
          name: "flutter",
          color: "orange-text-gradient",
        },
        {
          name: "dart",
          color: "pink-text-gradient",
        },
        
       
      ],
      image: "./images/audi.png",
      source_code_link: "https://github.com/TRUTHANIKET/ebal_app",
    },
    {
      name: "Ebal",
      description:
        "A learning Application for kids with audio speech alphabets and live pdf viewer and inbuilt browser.",
        url:"",
      tags: [
        {
          name: "flutter",
          color: "blue-text-gradient",
        },
        {
          name: "dart",
          color: "pink-text-gradient",
        },
        
        {
          name: "googleapis",
          color: "orange-text-gradient",
        },
      ],
      image: "./images/ebal.png",
      source_code_link: "https://github.com/TRUTHANIKET/ebal_app",
    },
    {
      name: "Tekilator",
      description:
        "A Text translator which can translate one language to another",
        url:"https://tekinatranslate-five.vercel.app",
      tags: [
        {
          name: "react",
          color: "blue-text-gradient",
        },
        {
          name: "azure",
          color: "green-text-gradient",
        },
        {
          name: "api",
          color: "pink-text-gradient",
        },
      ],
      image: "./images/tekilator.png",
      source_code_link: "https://tekinatranslate-vercel.app",
    },
    {
      name: "Ecom Sarree",
      description:
        "A website showcasing sarree's with responsive design and dynamic layout",
        url:"https://sarree-6jhy144t9-dumbaniket.vercel.app",
      tags: [
        {
          name: "react",
          color: "blue-text-gradient",
        },
        {
          name: "tailwind",
          color: "green-text-gradient",
        },
        {
          name: "nodejs",
          color: "pink-text-gradient",
        },
      ],
      image: "./images/sarree.jpg",
      source_code_link: "https://sarree-6jhy144t9-dumbaniket.vercel.app",
    },
    {
      name: "Movie Search",
      description:
        "A movie Search Application which indexes the latest movie from the search",
        url:"https://moviejano.netlify.app",
      tags: [
        {
          name: "react",
          color: "blue-text-gradient",
        },
        {
          name: "nodejs",
          color: "green-text-gradient",
        },
       
      ],
      image: "./images/moviesearch.jpg",
      source_code_link: "https://moviejano.netlify.app",
    },
    {
      name: "Youtube Downloader",
      description:
        "A web app to download youtube videos with backend supported by nodejs",
        url:"https://youtubemp6.vercel.app",
      tags: [
        {
          name: "react",
          color: "blue-text-gradient",
        },
        {
          name: "nodejs",
          color: "green-text-gradient",
        },
       
      ],
      image: "./images/youtubemp6.jpg",
      source_code_link: "https://youtubemp6.vercel.app",
    },
    {
      name: "Resume Builder",
      description:
        "A Web application to build your resume just by typing the input.",
        url:"https://dumbaniket.github.io/Resume-Builder/",
      tags: [
        {
          name: "react",
          color: "blue-text-gradient",
        },
        {
          name: "redux",
          color: "green-text-gradient",
        },
       , {
        name: "nodejs",
        color: "orange-text-gradient",
      },
      ],
      image: "./images/rbuilder.png",
      source_code_link: "https://dumbaniket.github.io/Resume-Builder/",
    },
    {
      name: "Django Ecommerce",
      description:
        "A Complete Ecommerce backend with authentication.",
        url:"https://github.com/TRUTHANIKET/django_ecommerce_full",
      tags: [
        {
          name: "django",
          color: "blue-text-gradient",
        },
        {
          name: "backend",
          color: "green-text-gradient",
        },
       , {
        name: "apps",
        color: "orange-text-gradient",
      },
      ],
      image: "./images/django.png",
      source_code_link: "https://github.com/TRUTHANIKET/django_ecommerce_full",
    },

    {
      name: "Fire Detector",
      description:
        "Fire detection using Open Cv with email alert (source code hidden due to some reason).",
        url:"/",
      tags: [
        {
          name: "python",
          color: "blue-text-gradient",
        },
        {
          name: "open-cv",
          color: "green-text-gradient",
        },
       , {
        name: "pytorch",
        color: "orange-text-gradient",
      },
      ],
      image: "https://i.ytimg.com/vi/__fk2BVsDIs/maxresdefault.jpg",
      source_code_link: "/",
    },
  ];
  return (
   <>
   <div className='mb-10 sm:mb-44'  id='project'><h1><br></br></h1></div>
   <div className='mt-10  '>
   <motion.div variants={textVariant()}>
        {/* <p className={`${styles.sectionSubText} `}>My work</p> */}
        <h2 className={`${styles.sectionHeadText}`}>Projects.</h2>
      </motion.div>

      <div className='w-full flex'>
        <motion.p
          variants={fadeIn("", "", 0.1, 1)}
          className='mt-3 mx-0 sm:mx-10 text-secondary text-[17px] max-w-3xl leading-[30px]'
        >
          Following projects showcases my skills and experience through
          real-world examples of my work. Each project is briefly described with
          links to code repositories and live demos in it. It reflects my
          ability to solve complex problems, work with different technologies,
          and manage projects effectively.
        </motion.p>
      </div>

      <div className='mt-10  flex justify-center flex-wrap gap-7'>
        {projects.map((project, index) => (
          <ProjectCard key={`project-${index}`} index={index} {...project} />
        ))}
      </div>
   </div>
    
   </>
  )
}
