import React from 'react'
import { motion } from "framer-motion";
import { fadeIn, textVariant } from "../utils/motion";
import {Tilt} from "react-tilt";
import { styles } from "../styles";
import '../index.css'





const ServiceCard = ({ index, title, icon,description ,url}) => (

    <div className="flex flex-wrap justify-center m-auto">
        <Tilt className='xs:w-[250px] w-full '>
     <a href={url}>
     <motion.div
        variants={fadeIn("right", "spring", index * 0.5, 0.75)}
        // className=' w-full  green-pink-gradient p-[1px] rounded-[20px] shadow-card'
        className="  w-full
       
        bg-gradient-to-r
         from-blue-600
        via-blue-300
        to-black
        p-[1px] rounded-[20px] shadow-card
        background-animate"
      >
        <div
          options={{
            max: 45,
            scale: 1,
            speed: 150,
          }}
          className='bg-black rounded-[20px] w-72 cursor-pointer hover:w-75 py-5 px-12 min-h-[280px] dis flex justify-evenly items-center flex-col'
        >
          
          <img
            src={icon}
            alt='dev'
            className='w-22 h-20 image object-contain'
            loading='lazy'
          />
  
          <h3 className='text-white text-[20px]  font-bold text-center'>
            {title}
          </h3>
         <div className=''>
         <p>{description}</p>
         </div>
        </div>
      </motion.div>
     </a>
    </Tilt>
    </div>
  
  );


export default function About() {
    const services = [
        {
          title: "OpenSource Contributor",
          icon: "./images/os.png",
          description:"Contributed to 5+ Open-Source Project through Github",
          url:"https://github.com/DUMBANIKET"
        },
        {
          title: "Web Developer",
          icon: "./images/web.png",
          description:"Creating Websites been a passion for me and i've been doing that for more than 2 years"
        },
        {
          title: "Full Stack Dev.",
          icon: "./images/full.png",
          description:"From a webdev the curiosity lead me be a Full Stack developer"
        },
        {
          title: "Content Creation",
          icon: "./images/yt.png",
          description:`Started youtube when i was a 13 yr old kid now im 20 and it's been an amazing progress to achieve a title named "Youtuber.You can click me to reach my Channel"`,
          url:"https://www.youtube.com/c/GODANIKET/videos"
        },
       
      ];
  return (
   <>
   <div className='mb-10 sm:mb-0'  id='about'></div>
    <div className=''>
    <motion.div variants={textVariant()}>
        {/* <p className={styles.sectionSubText}>Introduction</p> */}
       
        <h2 className="mx-0 sm:mx-10 text-white font-black md:text-[60px] sm:text-[50px] xs:text-[40px] text-[30px] ">About Me</h2>
      </motion.div>

      <motion.p
        variants={fadeIn("", "", 0.1, 1)}
        className='mt-4 mx-0 sm:mx-10 text-secondary text-[17px] max-w-3xl leading-[30px]'
      >
        Im Aniket Singh a passionate developer who love's to contibute to open source and collaborate and Learn new thing's.I love Problem Solving and Developing new Tech. I also work on creating content on youtube (*^_^*)
      </motion.p>


      <div className='mx-2  mt-10 flex flex-wrap gap-7 '>
        {services.map((service, index) => (
          <ServiceCard key={service.title} index={index} {...service} />
        ))}
      </div></div>
   </>
  )
}
